// https://www.taniarascia.com/redux-react-guide/
import * as actions from 'actions/cubesmodelActions';

export const initialState = {};

export default function cubesmodelReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CUBESMODEL:
      return { ...state, ...action.payload };
    case actions.GET_CUBESMODEL:
      return { ...state };
    default:
      return state;
  }
}
