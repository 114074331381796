import React, { useState, useContext } from 'react';
import { withRouter, useParams, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authService } from 'dataio/HttpRequest';
import { AuthContext } from 'context/UserContext';
import { validateLength, validateEmail } from 'utils/validators';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const context = useContext(AuthContext);

  const { page } = useParams();
  const userSession = useContext(AuthContext);
  console.log(userSession);

  const groupId = { labs: 5, lacarte: 10 };
  const redirects = { labs: `/${process.env.REACT_APP_BASEFOLDER}/signin`, lacarte: '/lacarte/' };

  const handleClick = () => {
    let result;
    setBackdrop(true);
    Promise.all([authService(
      {
        endpoint: '/auth/register',
        credentials: {
          email, password, firstname, lastname, origin: page, group: groupId[page],
        },
        method: 'POST',
      },
    )])
      .then((response) => {
        result = response[0];
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (result.status === 'success') {
          context.login(result);
          // props.history.push(redirects[page]);
          props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/message/checkemail`);
        } else if (result.status === 'fail') {
          toast.error(result.message);
        }
        setBackdrop(false);
      });
  };

  const onChangeFirstname = (event, val) => {
    setFirstname(event.target.value);
  };

  const onChangeLastname = (event, val) => {
    setLastname(event.target.value);
  };

  const onChangeEmail = (event, val) => {
    setEmail(event.target.value);
  };

  const onChangePassword = (event, val) => {
    setPassword(event.target.value);
  };

  const checkDisabled = (event, val) => {
    const lenerr = validateLength({
      email, password, firstname, lastname,
    });
    const emailerr = validateEmail({ email });
    const allerr = lenerr.concat(emailerr);
    console.log(allerr);
    const enabled = allerr.every((x) => x);
    return !enabled;
  };

  const followLink = (link) => () => {
    props.history.push(link);
  };

  if (!userSession.isAuth) {
    props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/labs/signin`);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={classes.paper}>


        {/* <Typography component="h1" variant="h5">
          Sign up
        </Typography> */}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={firstname}
                onChange={onChangeFirstname}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={lastname}
                onChange={onChangeLastname}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>


          </Grid>
          <Button
            disabled={checkDisabled()}
            onClick={handleClick}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>


      </div>

    </Container>
  );
};

export default withRouter(Profile);
