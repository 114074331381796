
import React from 'react';
import { useLocation } from 'react-router-dom';
import SignIn from './components/general/SignIn';
import SignUp from './components/general/SignUp';


import Validation from './components/general/Validation';
import Recovery from './components/general/Recovery';
import PwRecovery from './components/general/PwRecovery';
import Message from './components/general/Message';


import PriceList from './components/cardapio/PriceList';
import HomePage from './components/general/HomePage';


export function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for route <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export const ROUTES = [
  {
    name: 'index',
    path: `/${process.env.REACT_APP_BASEFOLDER}/`,
    private: false,
    exact: true,
    component: HomePage,
  },
  {
    name: 'signin',
    path: `/${process.env.REACT_APP_BASEFOLDER}/signin`,
    private: false,
    exact: false,
    component: SignIn,
  },
  {
    name: 'signup',
    path: `/${process.env.REACT_APP_BASEFOLDER}/signup`,
    private: false,
    exact: false,
    component: SignUp,
  },

  {
    name: 'validation',
    path: `/${process.env.REACT_APP_BASEFOLDER}/validation/:code`,
    private: false,
    exact: false,
    component: Validation,
  },
  {
    name: 'message',
    path: `/${process.env.REACT_APP_BASEFOLDER}/message/:code`,
    private: false,
    exact: false,
    component: Message,
  },
  {
    name: 'pwrecovery',
    path: `/${process.env.REACT_APP_BASEFOLDER}/pwrecovery/:code`,
    private: false,
    exact: false,
    component: PwRecovery,
  },
  {
    name: 'menu',
    path: `/${process.env.REACT_APP_LACARTEFOLDER}`,
    private: false,
    exact: false,
    component: PriceList,
  },
  {
    name: 'generalsignup',
    path: `/${process.env.REACT_APP_ACCOUNTFOLDER}/signup/:page`,
    private: false,
    exact: false,
    component: SignUp,
  },
  {
    name: 'recover',
    path: `/${process.env.REACT_APP_ACCOUNTFOLDER}/recover`,
    private: false,
    exact: false,
    component: Recovery,
  },
  {
    name: 'default',
    path: '/',
    private: false,
    exact: true,
    component: HomePage,
  },

];

export const ROUTE = ROUTES.reduce(
  (obj, { path, name }) => ({ ...obj, [name]: path }),
  {},
);
