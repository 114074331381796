const getServer = (mode) => {
  const envs = {
    cubes: process.env.REACT_APP_FLASKHOST,
    flask: process.env.REACT_APP_FLASKHOST,
    expenses: process.env.REACT_APP_EXPENSESHOST,
    games: process.env.REACT_APP_EXPENSESHOST,
    auth: process.env.REACT_APP_AUTHHOST,
  };
  return envs[mode];
};

const getPort = (mode) => {
  const envs = {
    cubes: process.env.REACT_APP_FLASKPORT === '80' ? '' : `:${process.env.REACT_APP_FLASKPORT}`,
    flask: process.env.REACT_APP_FLASKPORT === '80' ? '' : `:${process.env.REACT_APP_FLASKPORT}`,
    expenses: process.env.REACT_APP_EXPENSESPORT === '80' ? '' : `:${process.env.REACT_APP_EXPENSESPORT}`,
    games: process.env.REACT_APP_EXPENSESPORT === '80' ? '' : `:${process.env.REACT_APP_EXPENSESPORT}`,
    auth: process.env.REACT_APP_AUTHPORT === '80' ? '' : `:${process.env.REACT_APP_AUTHPORT}`,
  };
  return envs[mode];
};

async function HttpRequest(endpoint, type, method, payload = undefined, header = undefined) {
  const options = {};
  const server = getServer(type);
  const port = getPort(type);
  options.method = method;
  options.headers = {};
  if (header) {
    options.headers.Authorization = `Bearer ${header}`;
  }

  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(payload);
  }

  const protocol = process.env.REACT_APP_PROTOCOL;
  // const prefix = protocol === 'https' ? `/${type}` : '';
  const url = `${protocol}://${server}${port}${endpoint}`;

  const promise = fetch(url, options);
  const data = await promise;
  return data.json();

  /* if (data.status !== 200) {
    // throw new Error("Not 200 response")
    console.log(data);
    return { status: data.status };
  }
  // const data = await res.json();
  return data.json(); */
}


const composeCubesURL = function (agg) {
  const {
    drilldown, order, cut, obs, aggregates, token,
  } = agg;
  let url = '';
  if (drilldown) {
    url += `&drilldown=${drilldown}`;
  }
  if (order) {
    url += `&order=${order}`;
  }
  if (cut) {
    url += `&cut=${cut}`;
  }
  if (obs) {
    url += `&obs=${obs}`;
  }
  if (aggregates) {
    url += `&aggregates=${aggregates}`;
  }
  if (token) {
    url += `&token=${token}`;
  }
  return url;
};

const fetchFlaskData = function (params) {
  const {
    args, agg, method, endpoint,
    postendpoint,
  } = params;
    // console.log(credentials);
  let url;
  const payload = {};
  let formdata;
  url = endpoint;

  // console.log(method || 'GET' );
  if (args && args[0]) {
    url += `/${args[0]}`;
  }
  if (agg && agg[0]) {
    url += `?${composeCubesURL(agg[0])}`;
  }

  if (method === 'POST') {
    payload.url = url; // deep copy because url
    if (agg && agg[0] && agg[0].secondary) {
      const { cube } = agg[0].secondary;
      payload.secondary = `cube=${cube}${composeCubesURL(agg[0].secondary)}`;
    }

    formdata = payload;
    url = postendpoint;
  }

  return HttpRequest(url, 'flask', method || 'GET', formdata);
};

const fetchCubesData = function (agg, token = '', header = undefined, mode = undefined) {
  const { cube } = agg;
  const payload = undefined;
  let url;

  url = `/cube/${cube}/${mode || 'aggregate'}?`;
  url += composeCubesURL(agg) + token;
  return HttpRequest(url, 'cubes', 'GET', payload, header);
};

const fetchCubesInfo = function (endpoint, token = '') {
  const url = endpoint + token;
  return HttpRequest(url, 'cubes', 'GET');
};

const fetchCubesModel = function (cube, token = '') {
  return HttpRequest(`/cube/${cube}/model${token}`, 'cubes', 'GET');
};

const expensesDataService = function (endpoint, method = 'GET', options = undefined, payload = undefined) {
  return HttpRequest(endpoint, 'expenses', method, payload, options);
};

const gamesDataService = function (endpoint, header = '', method = 'GET', payload = undefined) {
  return HttpRequest(endpoint, 'games', method, payload, header);
};

const adminService = function (params) {
  const {
    endpoint, method, header, payload,
  } = params;
  return HttpRequest(endpoint, 'auth', method, payload, header);
};


const authService = function (params) {
  const {
    endpoint, method, credentials, header,
  } = params;
  return HttpRequest(endpoint, 'auth', method, credentials, header);
};

const taskManagerService = function (agg, token = '', mode = 'aggregate') {
  let url;

  url = `/taskmanager/${mode}?`;
  url += composeCubesURL(agg) + token;
  return HttpRequest(url, 'taskmanager', 'GET');
};

export {
  fetchCubesData, fetchCubesModel, fetchFlaskData, fetchCubesInfo, expensesDataService,
  authService, taskManagerService, gamesDataService, adminService,
};
