import * as d3Collection from 'd3-collection';
import moment from 'moment';


const sortByTot2 = (data, ...args) => {
  function compare(a, b) {
    if (a.tot < b.tot) {
      return -1;
    }
    if (a.tot > b.tot) {
      return 1;
    }
    return 0;
  }
  return data.sort(compare);
};


const getKeyByNameWithoutPeriod = (obj) => Object.keys(obj).filter((ele) => ele.indexOf('.') === -1);

const sortByTot = (data, ...args) => {
  function keysrt(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  if (data && data.length > 0) {
    let sortingKey;
    if (!args[0]) { // if key is undefined get 1st key that do not have a period (e.g totalfechadocomrecomendacoes_sum)
      sortingKey = getKeyByNameWithoutPeriod(data[0])[0];
    } else {
      sortingKey = args[0];
    }
    return (data.sort(keysrt(sortingKey)));
  }
};

const topRows = (data, ...args) => (data ? data.slice(0, args[0]) : []);

const sumNumericValues = (data, ...args) => {
  data.forEach((element) => {
    // console.log(sumValues(element));
    element.tot = Object.values(element).filter((value) => typeof (value) === 'number').reduce((a, b) => a + b);
  });
  return data;
};

const dateToUnixTime = (_data, ...args) => {
  // console.log(_data, args);
  const key = args[0];
  const format = args[1];
  if (_data) {
    _data.forEach((element) => {
      element.unixtime = moment(element[key], format).unix();
    });
  }
  return _data;
};

const unPivotAggs = (_data, ...args) => {
  const dateformat = args.pop();
  const cols = args[0];
  const filter = args[1];
  let filteredvalues;

  const results = [];
  let nestedvalues;
  let obj;

  if (_data) {
    _data.forEach((element) => {
      nestedvalues = element.values;

      if (filter) {
        filteredvalues = nestedvalues.find((x) => x['alerttype.code'] === filter);
        if (filteredvalues) {
          nestedvalues = [filteredvalues];
        } else {
          nestedvalues = [];
        }
      }

      obj = {};
      if (element.key.split('-')[0].length === 1) {
        obj.unixtime = moment(`0${element.key}`, dateformat).unix();
      } else if (element.key.split('-')[0].length === 2) {
        obj.unixtime = moment(element.key, dateformat).unix();
      }
      obj.time = element.key;

      cols.forEach((cell) => {
        obj[cell.label] = 0;
        nestedvalues.forEach((item) => {
          obj[cell.label] += item[cell.name];
        });
      });

      results.push(obj);
    });
  }

  return results;
};

const unPivotDims = (data, ...args) => {
  const cols = args[0];
  const flag = args[1];

  const results = [];
  let nestedvalues;
  let obj;

  data.forEach((element) => {
    nestedvalues = element.values;

    obj = {};
    obj.code = element.key;

    if (flag && flag === 'addtablefields') {
      obj.Name = nestedvalues[0].values[0]['alerttype.name'];
      obj['Assigned to'] = nestedvalues[0].values[0]['alerttype.assignedto'];
    }


    cols.forEach((cell) => {
      try {
        obj[cell.name] = nestedvalues.find((x) => x.key === cell.name).values[0].amount_sum;
      } catch (error) {
        if (!['code'].includes(cell.name)) {
          obj[cell.name] = 0;
        }
      }
    });
    results.push(obj);
  });
  // console.log(results);
  return results;
};

const sumvalues = (acumulador, obj) => acumulador + obj.amount_sum;


// https://github.com/d3/d3-collection#nests
// groups array by key (args[0]) returning new array with obj key and nested array
const nestData = (data, ...args) => {
  const result = d3Collection.nest()
    .key((d) => d[args[0]])
    // .key((d) => d[args[1]])
    .entries(data);
  return result;
};

const nestData1Lvl = (data, ...args) => {
  let result;
  if (data) {
    result = d3Collection.nest()
      .key((d) => {
        if (args.length === 2) {
          return `${d[args[0]]}-${d[args[1]]}`;
        } if (args.length === 3) {
          return `${d[args[0]]}-${d[args[1]]}-${d[args[2]]}`;
        }
      })
      .entries(data);
  }
  return result;
};


const groupDataByField = (data, key) => {
  // let result;
  const result = d3Collection.nest()
    .key((d) => d[key])
    .rollup((v) => v.reduce(sumvalues, 0))
    .entries(data);

  return result;
};


const groupAllData = (data) => {
  // let result;
  const result = d3Collection.nest()
    .rollup((v) => v.reduce(sumvalues, 0))
    .entries(data);

  return result;
};

const replaceKeyNames = (data, ...args) => {
  const result = data.map(({ 'status.name': name, amount_sum: value, ...rest }) => ({ name, value, ...rest }));

  return result;
};

const getCubesDataByKey = (data, ...args) => data[args[0]];

const roundNumber = (data, ...args) => {
  const exp = Math.pow(10, args[0]);
  let keys;
  let arr = [];
  if (!Array.isArray(data)) {
    // keys = Object.keys(data[0]);
    arr.push(data);
  } else if (typeof data === 'object') {
    // keys = Object.keys(data);
    arr = data;
  }

  if (arr[0]) {
    keys = Object.keys(arr[0]);

    const metrics = args.slice(1);
    arr.map((obj) => {
      keys.forEach((element) => {
        if (metrics.includes(element)) {
          obj[element] = Math.round(obj[element] * exp) / exp;
        }
      });
    });
  }
  return arr;
};

const objToArray = (obj, ...args) => {
  let result;
  if (obj) {
    result = Object.keys(obj).map((key) =>
    // return [Number(key), obj[key]];
    // console.log({name: key, value: obj[key]});
      ({ name: key, value: obj[key] }));
  }
  return result;
};


const filterArrayByKey = (data, ...args) => {
  let result;
  if (data) {
    result = data.filter((value) => args.includes(value.name));
  }
  return result;
};

const renameNameKey = (data, ...args) => {
  let result;
  if (data) {
    result = data.map(({ name, ...rest }) => ({ name: args[0][name], ...rest }));
  }
  return result;
};

// renames object key name considering args[0] = {"old name": "new name"}
const renameKeys = (data, ...args) => {
  if (data && data[0]) {
    data.map((obj) => {
      const objKeys = Object.keys(obj);
      const renameKeys = Object.keys(args[0]);
      objKeys.forEach((element) => {
        if (renameKeys.includes(element)) {
          return obj[args[0][element]] = obj[element];
        }
      });
    });
  }

  return data;
};

function sortColumn(a, b) {
  // console.log(data);

  const _key = this.tableData.columnOrder;

  let a_value;
  let b_value;

  if (_key === 4) {
    a_value = a.total_com / a.total_eventos;
    b_value = b.total_com / b.total_eventos;
  } else if (_key === 5) {
    a_value = a.total_rec / a.total_com;
    b_value = b.total_rec / b.total_com;
  } else if (_key === 6) {
    a_value = a.total_rec / a.total_eventos;
    b_value = b.total_rec / b.total_eventos;
  }

  if (a_value < b_value) {
    return -1;
  }
  if (a_value > b_value) {
    return 1;
  }
  return 0;
}

/* const flattenArray = (data, ...args) => {

  const aggKey = getKeyByNameWithoutPeriod(data[0])[0];
  const flatObj = {};
  data.forEach((element) => {
    console.log(element[aggKey]);
    flatObj[element[args[0]]] = element[aggKey];
  });

}; */

const flattenArray = (data, ...args) => {
  const res = [];
  data.forEach((element) => {
    const flatObj = {};
    flatObj.key = element.key;
    element.values.forEach((value) => {
      flatObj[value[args[0]]] = value[args[1]];
    });
    res.push(flatObj);
  });
  return res;
};


export {
  nestData, groupDataByField, groupAllData, unPivotDims, unPivotAggs,
  sumNumericValues, sortByTot, replaceKeyNames, nestData1Lvl, getCubesDataByKey, roundNumber,
  objToArray, filterArrayByKey, renameNameKey, renameKeys, sortColumn, topRows,
  flattenArray, dateToUnixTime,
};
