import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  useParams,
  Link, Route,
} from 'react-router-dom';
import moment from 'moment';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import { AuthContext } from 'context/UserContext';
import { gamesDataService } from 'dataio/HttpRequest';
import { htmldecode } from 'utils/Helpers';

const useStyles = makeStyles((theme) => ({

  main: {
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  pontuation: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
}));


export default function Rank(props) {
  const [ranks, setRanks] = useState([]);
  const [page, setPage] = React.useState(0);
  const authcontext = useContext(AuthContext);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const res = await gamesDataService(
        '/games/rank/',
        authcontext.auth_token,
      );

      setRanks(res);
    })();
  }, []);


  return (
        <React.Fragment >
          <Grid container spacing={0}>

          <Typography
            component="span"
            variant="h7"
            className={classes.pontuation}
            color="textPrimary"
          >
            Pontuação: 10 pontos por resposta correta. Bónus: 30, 20 e 10 para 10, 9 e 8 respostas corretas por quiz, respetivamente.
          </Typography>

        <Grid style={{ paddingRight: 5 }} item xs={12} md={12} >
        <Paper className={classes.main}>


        <Typography
          component="span"
          variant="h7"
          className={classes.inline}
          color="textPrimary"
        >
          Com 5 ou mais quizzes concluídos
        </Typography>


          <List >
            {ranks.filter((rank) => rank.count >= 5).map((rank, index) => {
              const labelId = `checkbox-list-label-${rank.user}`;

              return (
                <ListItem key={rank.user} role={undefined} dense >


                  <ListItemText
                      className={classes.button}
                      id={labelId}
                      primary={`${index + 1}º place - ${rank.name}`}
                      secondary={`Average score of ${rank.avgscore}% and average response time of ${Math.round(rank.avgresponsetime)} seg. in ${rank.count} quizzes`}
                      />
                  </ListItem>
              );
            })}
        </List>

          </Paper>


          <Paper className={classes.main}>


        <Typography
          component="span"
          variant="h7"
          className={classes.inline}
          color="textPrimary"
        >
          Com menos de 5 quizzes concluídos
        </Typography>


          <List >
            {ranks.filter((rank) => rank.count < 5).map((rank, index) => {
              const labelId = `checkbox-list-label-${rank.user}`;

              return (
                <ListItem key={rank.user} role={undefined} dense >


                  <ListItemText
                      className={classes.button}
                      id={labelId}
                      primary={`${index + 1}º place - ${rank.name}`}
                      secondary={`Average score of ${rank.avgscore}% and average response time of ${Math.round(rank.avgresponsetime)} seg. in ${rank.count} quizzes`}
                      />
                  </ListItem>
              );
            })}
        </List>

          </Paper>
          </Grid>
          </Grid>
        </React.Fragment>
  );
}
