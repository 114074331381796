import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MailIcon from '@material-ui/icons/Mail';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentIcon from '@material-ui/icons/Comment';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    // width: 900,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    // width: 200,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  category: {
    fontSize: 18,
    align: 'center',
    fontFamily: 'Patrick Hand',
    color: 'darkblue',
  },
  item: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // color: '#241f20',
    // height: 48,
    padding: '0 15px',
    fontFamily: 'Patrick Hand',
    color: 'darkblue',
    fontSize: 16,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    // minWidth: 275,
  },
  list: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  /* playball: {
    fontFamily: 'Playball',
  }, */
}));

function PriceListContainer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const bull = <span className={classes.bullet}>•</span>;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([0]);
  const [menu, setMenu] = React.useState(0);
  // const [menudata, setMenudata] = React.useState();
  const { data } = props;
  let menudata;
  let menudataCol1;
  let menudataCol2;
  const changeMenu = (menudata, index) => () => {
    setMenu(index);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


  // const container = window !== undefined ? () => window().document.body : undefined;

  const getCategoryOptions = (category) => (

    category.options.map((value) => {
      const labelId = `checkbox-list-label-${value.id}`;
      return (
        <ListItem

          key={value.id}
          role={undefined}
          dense button
          onClick={handleToggle(value)}>
            <ListItemText

              classes={{
                primary: classes.item, // class name, e.g. `classes-nesting-label-x`
              }}
              id={labelId}
              primary={`${value.name}`} />
            <ListItemSecondaryAction
              className={classes.item}
              >
                {value.price} €
              </ListItemSecondaryAction>
        </ListItem>
      );
    })
  );

  const getColumnItems = (dataitems) => (

    dataitems.map((category, index) => (
    <Paper key={`paper${index}`} className={classes.paper}>
      <Card className={classes.card}>
          <CardContent key={index}>
                <Typography className={classes.category} align="center" color="secondary" gutterBottom>
                {category.name}
                </Typography>
                <List className={classes.list}>
                {getCategoryOptions(category)}
                </List>
              </CardContent>
      </Card>
    </Paper>))
  );

  const preventDefault = (event) => event.preventDefault();


  // console.log(data);
  if (data.length === 0) {
    return (<div className={classes.root}></div>);
  }
  menudata = data.menus[menu];
  const numberEleCol1 = Math.ceil(menudata.menu_list.length / 2);
  menudataCol1 = menudata.menu_list.slice(0, numberEleCol1);
  menudataCol2 = menudata.menu_list.slice(numberEleCol1);
  console.log(menudataCol1);
  console.log(menudataCol2);

  return (
    <div className={classes.root}>
      <CssBaseline />

        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={0}
        >
          <Grid item xs={12} >
          <Typography variant="h5" className={classes.category} style={{ fontSize: 30 }}>{data.name}</Typography>
          <Typography variant="h5" className={classes.category}>Menu {menudata.name}</Typography>
          <Typography variant="h6" className={classes.category}>
            <Link href="#" onClick={preventDefault}>PT</Link>
            &nbsp;/&nbsp;
            <Link href="#" onClick={preventDefault}>EN</Link>
            &nbsp;/&nbsp;
            <Link href="#" onClick={preventDefault}>ES</Link>
            &nbsp;/&nbsp;
            <Link href="#" onClick={preventDefault}>FR</Link>
            &nbsp;/&nbsp;
            <Link href="#" onClick={preventDefault}>DE</Link>
          </Typography>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {data.menus.map((menu, index) => (
              <Button size="small" onClick={changeMenu(menu, index)} className={classes.category}>{menu.name}</Button>
            ))
            }
          </ButtonGroup>
          </Grid>
            <Grid item xs={12} md={6} >
              {getColumnItems(menudataCol1)}
            </Grid>
            <Grid item xs={12} md={6}>
                {getColumnItems(menudataCol2)}
            </Grid>
        </Grid>

    </div>
  );
}

{ /* <Typography className={classes.title} color="textSecondary" gutterBottom>
  Word of the Day
  </Typography>
  <Typography variant="h5" component="h2">
  be{bull}nev{bull}o{bull}lent
  </Typography>
  <Typography className={classes.pos} color="textSecondary">
  adjective
  </Typography>
  <Typography variant="body2" component="p">
  well meaning and kindly.
  <br />
  {'"a benevolent smile"'}
  </Typography> */ }


{ /*
  <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
  <ListItemIcon>
    <Checkbox
      edge="start"
      checked={checked.indexOf(value) !== -1}
      tabIndex={-1}
      disableRipple
      inputProps={{ 'aria-labelledby': labelId }}
  />
  </ListItemIcon>
  <ListItemText id={labelId} primary={`Cozido à Portuguesa ${value + 1}`} />
  <ListItemSecondaryAction>
  <IconButton edge="end" aria-label="comments">
      <CommentIcon />
  </IconButton>10,5 €
  </ListItemSecondaryAction>
</ListItem>
  */ }

PriceListContainer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PriceListContainer;
