import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
/*import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';*/
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { toast } from 'react-toastify';
import { authService } from 'dataio/HttpRequest';
import { getDefaultRoute } from 'utils/Helpers';
import { AuthContext } from 'context/UserContext';
import { userContext } from 'actions/usercontextActions';
import Copyright from './Copyright';

const logo = `${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGO}`

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const [username, setUsername] = useState('');
  const [pw, setPw] = useState('');
  const [keep, setKeep] = useState(JSON.parse(localStorage.getItem('keep')));

  const dispatch = useDispatch();
  const context = useContext(AuthContext);
  const classes = useStyles();

  const login = (_user, _pw, _keep) => {
    let result; 
    let rest;
    
    Promise.all([authService(
      {
        endpoint: '/auth/login',
        credentials: { email: _user, password: _pw, product: 'labs' },
        method: 'POST',
      },
    )])
      .then((response) => {
        // destructuring arrays; page becomes 1st elemente in array and rest all the others
        [result, ...rest] = response;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (result.status === 'success') {
          // console.log(result);
          result.permissions = result.permissions
            .filter((perm) => !['cardapio'].includes(perm.name))
            .filter((perm) => perm.type === 'routes')
            .sort((perma, permb) => ((perma.descr > permb.descr) ? 1 : ((permb.descr > perma.descr) ? -1 : 0)));
          //console.log('result', result);
          if (_keep){
            localStorage.setItem("user", JSON.stringify(result));
          }
          //localStorage.setItem("keep", _keep);
          context.login(result);
          dispatch(userContext(result));
          const page = getDefaultRoute(result.permissions, result.observations);
          props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/${page}`);
          // props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/admin`);
        } else if (result.status === 'fail') {
          toast.error(result.message, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleClick = () => {
    login(username, pw, keep);
  };

  /*const defaultLogin = (_username, _pw) => {
    login(_username, _pw);
  };*/

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      handleClick();
    }
  };

  const onChangeUsername = (event, val) => {
    setUsername(event.target.value);
  };

  const onChangePw = (event, val) => {
    setPw(event.target.value);
  };

  const followLink = (link) => () => {
    props.history.push(link);
  };

  const checkDisabled = (event, val) => {
    if (username.length === 0 || pw.length === 0) {
      return true;
    }
    return false;
  };

  /*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    defaultLogin(process.env.REACT_APP_FLASK_USER, process.env.REACT_APP_FLASK_PW);
  }*/

  /*useEffect(() => {
    const i = 1;
  }, []);*/

  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ height: 40 }} className="App-logo" alt="logo" />
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
          <TextField
            value={username}
            onChange={onChangeUsername}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            value={pw}
            onChange={onChangePw}
            onKeyPress={handleKeyPress}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox 
                checked={keep} 
                onChange={
                  ()=>{
                    localStorage.setItem("keep", !keep);
                    setKeep(!keep);
                  }
                } 
                color="primary" 
              />
            }
            label="Keep me logged in"
          /> 
          <Button
            // type="submit"
            onClick={handleClick}
            disabled={checkDisabled()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          >
            <Grid item xs>
              <Link href="#" style={{ left: 0 }} onClick={followLink(`/${process.env.REACT_APP_ACCOUNTFOLDER}/recover`)} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs>
              <Link href="#" onClick={followLink(`/${process.env.REACT_APP_ACCOUNTFOLDER}/signup/labs`)} variant="body2">
                Don't have an account?
              </Link>
            </Grid>
          </Grid>
        {/* </form> */}


      </div>


      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>

  );
}

export default withRouter(SignIn);
