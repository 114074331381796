// Create Redux action types
export const SET_USERCONTEXT = 'SET USERCONTEXT';
export const GET_USERCONTEXT = 'GET USERCONTEXT';

export const setUserContext = (context) => ({
  type: SET_USERCONTEXT,
  payload: context,
});

// Combine them all in an asynchronous thunk
export function userContext(context) {
  return async (dispatch) => {
    dispatch(setUserContext(context));
  };
}
