import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CardapioList from 'components/cardapio/CardapioList';
import CardapioDetail from 'components/cardapio/CardapioDetail';
import { gamesDataService, expensesDataService } from 'dataio/HttpRequest';

import { AuthContext } from 'context/UserContext';
import PriceListContainer from './PriceListContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

export default function PriceList(props) {
  const classes = useStyles();
  const queryString = window.location.search;
  // const cellHeight = 180;
  const cellHeight = 40;
  const context = useContext(AuthContext);
  const urlParams = new URLSearchParams(queryString);
  // console.log(props);
  const [data, setData] = useState([]);
  // const [menus, setMenus] = useState([]);

  let id;
  if (urlParams.has('id')) {
    id = urlParams.get('id');
  } else {
    id = props.data.identifier;
  }

  console.log(data);

  useEffect(() => {
    (async () => {
      const res = await expensesDataService(`/cardapio/publicmenus/${id}`, 'GET');
      console.log(res);
      res.error ? toast.error('Something went wrong') : setData(res);
    })();
  }, [id]);

  return (
        <React.Fragment>
            {/* <Typography variant="h6">Ementa {data.name}</Typography> */}
            {/* <Typography variant="body2">{id}</Typography>
            <QRCode value={`${window.location.origin}/menu?id=${id}`} /> */}

            <PriceListContainer data={data}/>
        </React.Fragment>
  );
}
