import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
// import Draggable from 'react-draggable';
import LinearProgress from '@material-ui/core/LinearProgress';
import { adminService } from 'dataio/HttpRequest';

import { AuthContext } from 'context/UserContext';
import Groups from 'components/admin/Groups';


const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },

}));

function PaperComponent(props) {
  return (<Paper {...props} />);
  /* return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  ); */
}

export default function Users(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const authcontext = useContext(AuthContext);
  const [open, setOpen] = React.useState({ state: false, data: undefined, mode: undefined });
  const classes = useStyles();
  let groups;
  const handleDeleteOpen = (row) => {
    setOpen({ state: true, data: row, mode: 'delete' });
  };


  const cols = [
    { title: 'E-mail', field: 'email', type: 'string' },
    { title: 'Group', field: 'group', type: 'string' },
    { title: 'Last login', field: 'lastlogin', type: 'datetime' },
    { title: 'Last access', field: 'lastaccess', type: 'string' },
  ];


  const receiveGroups = (_groups) => {
    console.log(_groups);
    groups = _groups;
  };

  const changeGroups = async (_id, _groups) => {
    console.log(_id, _groups);
    const res = await adminService(
      {
        endpoint: `/auth/groups/${_id}`,
        method: 'PUT',
        header: authcontext.auth_token,
        payload: { groups: _groups },
      },
    );
  };


  const handleEditOpen = (row) => {
    // console.log(row);
    setOpen({ state: true, data: row, mode: 'groups' });
    // props.editButton(rowData);
  };

  const deleteUser = async (id) => {
    const res = await adminService(
      {
        endpoint: `/auth/user/${id}`,
        method: 'DELETE',
        header: authcontext.auth_token,
      },
    );

    if (res.status === 'fail') {
      toast.error(res.message);
    } else {
      setData(data.filter((ele) => ele.id !== id));
      toast(
        'User deleted !',
        { position: toast.POSITION.TOP_CENTER, autoClose: 1000 },
      );
    }
  };

  const handleClose = (action) => {
    if (action) {
      if (open.mode === 'delete') {
        deleteUser(open.data.id);
      } else if (open.mode === 'groups') {
        changeGroups(open.data.id, groups);
      }
    }
    setOpen({ state: false });
  };

  const getContent = (row) => {
    if (open.mode === 'delete') {
      return (<DialogContentText>
        Are you sure you want to delete this user?
      </DialogContentText>);
    }
    if (open.mode === 'groups') {
      return (<Groups user={row} receiveGroups={receiveGroups}></Groups>);
    }
    return true;
  };


  // apply the effect only if datecut, dimcuts have changed between re-renders
  useEffect(() => {
    (async () => {
      setLoading(true);
      let res;
      try {
        res = await adminService({
          endpoint: '/auth/users', method: 'GET', header: authcontext.auth_token, payload: undefined,
        });
        res.map((ele) => {
          if (ele.lastaccess) {
            const keys = Object.keys(ele.lastaccess).filter((key) => ['browser', 'platform'].includes(key));
            ele.lastaccess = keys.map((key) => `| ${ele.lastaccess[key]} |`);
          }
          ele.group = ele.roles.reduce((accumulator, currentValue) => `${accumulator} | ${currentValue.name} `, ' ');
          return true;
        });
      } catch (error) {
        console.log(error);
      }

      res.error ? toast.error('Something went wrong') : setData(res);
      setLoading(false);
    })();
  }, []);


  return (

    <React.Fragment>
      <div className={classes.progress}>
      {loading ? <LinearProgress color="secondary" /> : null}
    </div>


    <Dialog
        open={open.state}
        onClose={() => handleClose(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {open.mode === 'delete' ? 'Delete User' : 'Edit User'}
        </DialogTitle>
        <DialogContent>
          { getContent(open) }
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    <Grid container spacing={1}>

      <Grid item xs={12} md={12} >
          <MaterialTable style={{ color: 'rgb(101, 129, 157)' }}
            columns={cols}
            data={data}
            title=''
            actions={[
              {
                icon: 'edit',
                iconProps: { color: 'action' },
                tooltip: 'Edit User',
                onClick: (event, rowData) => handleEditOpen(rowData),
              },
              (rowData) => ({
                icon: 'delete',
                iconProps: { color: 'action' },
                tooltip: 'Delete User',
                // eslint-disable-next-line no-restricted-globals
                // onClick: (event, rowData) => del(rowData),
                onClick: (event, rowData) => handleDeleteOpen(rowData),

              }),
            ]}
            options={{
              exportButton: false,
              exportAllData: false,
              toolbar: true,
              pageSize: 20,
              actionsColumnIndex: -1,
            }}
          />
      </Grid>

  </Grid>
  </React.Fragment>
  );
}
