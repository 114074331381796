import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/UserContext';


const useStyles = makeStyles((theme) => ({
  textlink: {
    textDecoration: 'none',
    color: '#2309b0',
  },
}));

const COLORS = ['#2309b0', '#188615', '#cf2e5a', '#bc9e15', '#13c0b5', '#df1de5'];

function MainListItems(props) {
  const { handleSecDrawerClose } = props;
  const classes = useStyles();
  const context = useContext(AuthContext);
  // const routes = context.permissions.filter((perm) => perm.type === 'routes');
  const routes = context.permissions;

  const icons = {
    gym: 'directions_run',
    admin: 'supervisor_account',
    expenses: 'attach_money',
    games: 'quiz',
  };

  return (
  <div>

  { routes.map((route, index) => (
    <ListItem key={route.name} button>

    <Tooltip title={route.descr} aria-label={route.name}>
      <ListItemIcon>
        <Link to={`/${process.env.REACT_APP_BASEFOLDER}/${route.name}`}>
           <i className="material-icons">{icons[route.name] || 'center_focus_strong'}</i>
        </Link>
      </ListItemIcon>
      </Tooltip>


        <Link to={`/${process.env.REACT_APP_BASEFOLDER}/${route.name}`} onClick={handleSecDrawerClose} className={classes.textlink}>
          <ListItemText primary={route.descr} />
        </Link>

    </ListItem>
  ))}

  </div>
  );
}


export { MainListItems };
