// https://www.taniarascia.com/redux-react-guide/
import * as actions from 'actions/usercontextActions';

export const initialState = {
  usercontext: {},
};

export default function usercontextReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USERCONTEXT:
      return { ...state, usercontext: action.payload };
    case actions.GET_USERCONTEXT:
      return { ...state };
    default:
      return state;
  }
}
