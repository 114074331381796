import React from 'react';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    isAuth: false,
    userId: null,
    username: null,
    email: null,
    auth_token: null,
    permissions: [],
    cubestoken: null,
    roles: [],
  };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    // this.setPermissions = this.setPermissions.bind(this);
    // this.setGroups = this.setGroups.bind(this);
  }

  async login(useraccount) {
    const {
      auth_token,
      email,
      name,
      id,
      cubestoken,
      roles,
      permissions,
    } = useraccount;
    await this.setState({
      isAuth: true,
      auth_token,
      userId: id,
      username: `${name}`,
      email,
      cubestoken,
      roles,
      permissions,
    });
  }

  logout() {
    this.setState({ isAuth: false });
  }

  setPermissions(p) {
    this.setState({ permissions: p });
  }

  setGroups(g) {
    this.setState({ groups: g });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          userId: this.state.userId,
          username: this.state.username,
          email: this.state.email,
          auth_token: this.state.auth_token,
          permissions: this.state.permissions,
          roles: this.state.roles,
          cubestoken: this.state.cubestoken,
          login: this.login,
          logout: this.logout,
          // setPermissions: this.setPermissions,
          // setGroups: this.setGroups,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
