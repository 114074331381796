// https://www.taniarascia.com/redux-react-guide/

// Create Redux action types
export const SET_CUBETOKEN = 'SET CUBETOKEN';
export const GET_CUBETOKEN = 'GET CUBETOKEN';


export const setCubetoken = (token) => ({
  type: SET_CUBETOKEN,
  payload: token,
});


// Combine them all in an asynchronous thunk
export function setToken(token) {
  return async (dispatch) => {
    dispatch(setCubetoken(token));

    /* try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts')
        const data = await response.json()

        dispatch(getPostsSuccess(data))
      } catch (error) {
        dispatch(getPostsFailure())
      } */
  };
}
