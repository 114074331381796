import React, { useState, useContext } from 'react';
import { withRouter, useParams, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authService } from 'dataio/HttpRequest';
import Copyright from './Copyright';

const logo = `${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGO}`

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Message(props) {
  const classes = useStyles();
  const { code } = useParams();

  const messages = {
    checkemail: 'Please check your e-mail and follow the link to validate your account',
  };


  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ height: 40, marginBottom: 20 }} alt="logo" />

        <Typography variant="h7" component="h2">
          {messages[code] || '----'}
        </Typography>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>

  );
}

export default withRouter(Message);
