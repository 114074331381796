import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable, { MTableToolbar } from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Pagination from '@material-ui/lab/Pagination';
import { gamesDataService } from 'dataio/HttpRequest';
import { AuthContext } from 'context/UserContext';

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  main: {
    // width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  button: {
    flexGrow: 1,
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
  },
  pagination: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },


}));

export default function GamesTable(props) {
  const [todo, setTodo] = useState([]);
  const [kinds, setKinds] = useState([]);
  const [kind, setKind] = useState();
  const [done, setDone] = useState([]);
  const [pagetodo, setPageTodo] = useState(0);
  const [pagedone, setPageDone] = useState(0);
  const [loading, setLoading] = useState(false);
  const pagesize = 10;


  const context = useContext(AuthContext);

  const classes = useStyles();

  // console.log(props);

  const filterQuiz = () => (_kind) => {
    setKind(_kind.target.value);
    (async () => {
      const res = await gamesDataService(
        `/games/kind/${_kind.target.value}`,
        context.auth_token,
      );
      if (res.error) {
        toast.error('Something went wrong');
      } else {
        setTodo(res[0]);
        setDone(res[1]);
      }
    })();
  };

  const paginateTodo = (event, value) => {
    setPageTodo(value - 1);
  };

  const paginateDone = (event, value) => {
    setPageDone(value - 1);
  };

  const getGameIcon = (quiz) => (quiz.mode === 'timer 60' ? 'timer' : null);

  // apply the effect only if context.cubestoken have changed between re-renders
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await gamesDataService('/games/', context.auth_token);
      res.error ? toast.error('Something went wrong') : setTodo(res);
      setLoading(false);
    })();

    (async () => {
      const res = await gamesDataService('/games/scores', context.auth_token);
      res.error ? toast.error('Something went wrong') : setDone(res);
    })();

    (async () => {
      const res = await gamesDataService('/games/kinds', context.auth_token);
      res.error ? toast.error('Something went wrong') : setKinds([...kinds, ...res]);
    })();
  }, [context.cubestoken]);


  return (

    <React.Fragment>

    <div className={classes.progress}>
      {loading ? <LinearProgress color="secondary" /> : null}
    </div>

    <Grid container spacing={0}>

    <Grid item xs={12} md={12} >


      {/* <Typography
          component="span"
          variant="h6"
          className={classes.inline}
          color="textPrimary"
        >
          Cada Quiz tem 10 perguntas. Tens um bónus, se responderes em menos de 90 segundos e acertares pelo menos 8 respostas.
      </Typography> */}

      <FormControl className={classes.formControl}>
        <InputLabel id="simple-select-label">Escolhe a categoria</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={kind}
          onChange={filterQuiz()}
          autoWidth={true}
          displayEmpty={true}
          width={200}
        >
          <MenuItem value="all">All</MenuItem>
          {kinds.map((ele, index) => (<MenuItem key={index} value={ele.kind}>{ele.kind}</MenuItem>))}

        </Select>
        </FormControl>

    </Grid>


    <Grid style={{ paddingRight: 5 }} item xs={12} md={12} >
      <Paper className={classes.main}>
      <Typography
          component="span"
          variant="h6"
          className={classes.inline}
          color="textPrimary"
        >
          Escolhe um Quiz
        </Typography>

        <List >
            {todo.slice(pagetodo * pagesize, (pagetodo * pagesize) + pagesize).map((quiz) => {
              const labelId = `checkbox-list-label-${quiz.id}`;

              return (
                <ListItem key={quiz.id} role={undefined} dense >
                    <ListItemIcon>

                    <Checkbox
                        edge="start"
                        // checked={checked.indexOf(toquizdo.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        onChange={ () => { props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/doquiz/${quiz.id}`); }}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                    </ListItemIcon>

                  <ListItemText
                      className={classes.button}
                      id={labelId}
                      primary={`Quiz ${quiz.id} - 10 questions / 90 seg.`}
                      secondary={` ${quiz.kind} `}
                      />
                      <IconButton edge="end" aria-label="timer">
                        <Icon>{getGameIcon(quiz)}</Icon>
                      </IconButton>

                  </ListItem>
              );
            })}
        </List>

          <Pagination
            className={classes.pagination}
            variant="outlined"
            shape="rounded"
            count={todo ? Math.ceil(todo.length / pagesize) : 0}
            color="secondary"
            onChange={paginateTodo} />

        </Paper>
      </Grid>

  </Grid>
  </React.Fragment>
  );
}
