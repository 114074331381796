import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  useParams,
  Link, Route,
} from 'react-router-dom';
import moment from 'moment';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import { AuthContext } from 'context/UserContext';
import { gamesDataService } from 'dataio/HttpRequest';
import { htmldecode } from 'utils/Helpers';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(400 + theme.spacing(1))]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',

    },
  },
}));


export default function Score(props) {
  const { quiz, user } = useParams();
  const [data, setData] = useState();
  const [page, setPage] = React.useState(0);
  const authcontext = useContext(AuthContext);
  const classes = useStyles();

  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  const getResults = () => {
    if (data && data.answers) {
      // res = data.answers[answer];
      const labelId = `checkbox-list-label-${data.id}`;


      return (
        <React.Fragment>

        { data.answers[page].correct === 'f'
          ? <Icon style={{ color: 'red' }}>thumb_down</Icon>
          : <Icon style={{ color: 'green' }}>thumb_up</Icon>
        }


        <ListItem key={data.id} role={undefined} dense button >
            <ListItemText className={classes.button} id={labelId}
            primary={
              htmldecode(data.game.find((ele) => ele.id === data.answers[page].id)
                .questions.question)
            }
            />
        </ListItem>


        <ListItem role={undefined} dense button >
          <ListItemText
            className={classes.button}
            primary={`Respondeu ${htmldecode(data.answers[page].text)}`}
            secondary={`A resposta está ${data.answers[page].correct === 'f' ? 'incorreta' : 'correcta'}`}
          />
        </ListItem>

        <ListItem role={undefined} dense button >
          <ListItemText
            className={classes.button}
            primary={data.answers[page].correct === 'f' ? `A resposta correta seria: ${htmldecode(data.game.find((ele) => ele.id === data.answers[page].id).questions.correct_answer)}` : ''}
          />
        </ListItem>
        </React.Fragment>
      );
    }
    return (<ListItem>Sorry this quiz wasn't properly done</ListItem>);
  };


  useEffect(() => {
    (async () => {
      const res = await gamesDataService(
        `/games/score/${quiz}`,
        authcontext.auth_token,
      );
      console.log(res);
      setData(res);
      // data.error ? toast.error('Something went wrong') : setData(res);
    })();
  }, [quiz, user]);


  return (
        <React.Fragment >

        <Grid container spacing={0}>
          <Grid style={{ paddingRight: 5 }} item xs={12} md={12} >

            <Paper className={classes.main}>
                <List>
                  <ListItem role={undefined} dense button >
                    <ListItemText className={classes.question}>
                    SCORE: {data ? data.score : ''}
                    </ListItemText>
                    <ListItemText className={classes.question}>
                    RESPONSE TIME: {data ? `${data.responsetime} seg.` : ''}
                    </ListItemText>

                  </ListItem>

                  <ListItem role={undefined} dense button >
                    <ListItemText
                      className={classes.question}
                      primaryTypographyProps={{ align: 'center' }}
                      // primary={`DATA: ${moment(data.moment).format('lll'))}` : ''}
                      primary={`DATA: ${data ? moment(data.moment).format('ll') : '---'}`}
                    />
                  </ListItem>
                </List>

                {/* <List>
                  {getResults()}
                </List> */}

                <List>
                  {
                  data && data.answers
                  && data.answers.map((answer, index) => {
                    const color = data.answers[index].correct === 't' ? 'green' : 'red';
                    const backgroundColor = index % 2 === 0 ? '#dcdbdb' : '#c8c5c5';
                    const correct = data.answers[index].correct === 'f' ? ` - A resposta correta seria: ${htmldecode(data.game[index].questions.correct_answer)}` : '';
                    return (<span>
                            <ListItem style={{ backgroundColor }} key={answer.id} role={undefined} dense button >
                                <ListItemText className={classes.button}
                                primary={htmldecode(data.game[index].questions.question)}
                                secondary={`Respondeu ${htmldecode(data.answers[index].text)} ${correct}`}
                                />
                                <Icon style={{ color }}>{data.answers[index].correct === 't' ? 'thumb_up' : 'thumb_down'}</Icon>
                            </ListItem>
                  </span>);
                  })
                    }
                </List>

                {/* <Pagination count={data && data.answers ? data.answers.length : 10} color="secondary" onChange={handleChange} /> */}
            </Paper>


            </Grid>
          </Grid>

        </React.Fragment>
  );
}
