import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import { Route } from 'react-router-dom';
import { AuthContext } from 'context/UserContext';
import Users from 'components/admin/Users';

export default function Administration(props) {
  const [listVisible, setListVisible] = useState(true);
  const [mode, setMode] = useState('table');
  const [loading, setLoading] = useState(false);
  const context = useContext(AuthContext);

  return (
        <React.Fragment >

          <Route exact path="/labs/admin">
            <Users />
          </Route>

          <Route path="/labs/admin/groups/:group/">
            {/* <Quiz {...props} mode={mode} /> */}
          </Route>

          <Route path="/labs/admin/permissions/:permission">
            {/* <Score {...props} mode={mode} /> */}
          </Route>

        </React.Fragment>
  );
}
