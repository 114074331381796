import React, { useState, useContext } from 'react';
import { withRouter, useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authService } from 'dataio/HttpRequest';
import Copyright from './Copyright';

const logo = `${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGO}`

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Validation(props) {
  const classes = useStyles();
  const { code } = useParams();

  const validateCode = async () => {
    const res = await authService({
      endpoint: `/auth/validate/${code}`, method: 'GET', payload: undefined,
    });

    if (res.status === 'success') {
      props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/signin`);
    } else {
      toast.error(res.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ height: 40 }} className="App-logo" alt="logo" />

          <Button
            onClick={validateCode}
            disabled={false}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Validate User
          </Button>


      </div>


      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>

  );
}

export default withRouter(Validation);
