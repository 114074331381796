import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import Button from '@material-ui/core/Button';

export default function QuizButtons(props) {
  const showButton = (identifier) => {
    const path = window.location.pathname;
    if (path === identifier) { return false; }
    return true;
  };

  return (
        <React.Fragment >
            <Button
                    style={{ display: showButton('/labs/games/quiz') ? 'inline' : 'none' }}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/quiz`)}
                >Let's do it
                </Button>

                <Button
                    style={{ display: showButton('/labs/games/done') ? 'inline' : 'none' }}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/done`)}
                >My Quizzes
                </Button>

                <Button
                    style={{ display: showButton('/labs/games/rank') ? 'inline' : 'none' }}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/rank`)}
                >Rank
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games`)}
                >Bet
                </Button>
                </React.Fragment>
  );
}
