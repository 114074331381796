
// Create Redux action types
export const SET_ALLOWDATERANGE = 'SET ALLOWDATERANGE';
export const GET_ALLOWDATERANGE = 'GET ALLOWDATERANGE';


export const setAllowDateRange = (option) => ({
  type: SET_ALLOWDATERANGE,
  payload: option,
});


// Combine them all in an asynchronous thunk
export function allowDateRange(option) {
  return async (dispatch) => {
    dispatch(setAllowDateRange(option));
  };
}
