import React, { SVGProps } from "react";
import './styles/banner.css';

const text = "IZarco";

export const Footer = () => {

    /*useEffect(() => {
        const styleTag = document.createElement("style");
        document.head.appendChild(styleTag);
        CSSRules.forEach((rule) =>
            styleTag.sheet?.insertRule(rule, styleTag.sheet.cssRules.length),
        );
    }, []);*/

    return (
        <footer
            className="banner bg-top-announcement"
            style={{
                width: "100%",
                height: "35px",
            }}
        >
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    //paddingLeft: "200px",
                    width: "100%",
                    maxWidth: "100vw",
                    height: "100%",
                    borderBottom: "1px solid #47ebeb26",
                }}
            >
                
                
                <Text text={text} />
            </div>
        </footer>
    );
};

const Text = ({ text }) => {
    return (
        
        <a
            className="gh-link"
            href="#"
            //href="https://www.izarco.com"
            //target="_blank"
            style={{
                position: "absolute",
                height: "100%",
                padding: "0 60px",
                display: "flex",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage:
                    "linear-gradient(90deg, rgba(31, 63, 72, 0.00) 0%, #1F3F48 10%, #1F3F48 90%, rgba(31, 63, 72, 0.00) 100%)",
            }}
        >
            <div
                style={{
                    color: "#fff",
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                }}
            >
                <span
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img width="22" src={`${process.env.PUBLIC_URL}/img/logo8.png`} />  
                </span>
                <span
                    className="text"
                    style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        textDecoration: "underline"
                    }}
                >
                   {text}
                </span>
                <span
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                
                </span>
                
            </div>
        </a>
        
    );
};

const GlowSmall = ({ style, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={80}
            height={40}
            fill="none"
            style={{
                opacity: 1,
                animation:
                    "top-announcement-glow 1s ease-in-out infinite alternate",
                ...style,
            }}
        >
            
            <defs>
                <radialGradient
                    id={`${props.id}-a`}
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(0 40 -40 0 40 0)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#47EBEB" />
                    <stop offset={1} stopColor="#47EBEB" stopOpacity={0} />
                </radialGradient>
            </defs>
        </svg>
    );
};

const GlowBig = ({ style, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={48}
        fill="none"
        {...props}
        style={{
            opacity: 1,
            animation:
                "top-announcement-glow 1s ease-in-out infinite alternate",
            ...style,
        }}
    >
        
        <defs>
            <radialGradient
                id={`${props.id}-a`}
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(0 60 -60 0 60 24)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#47EBEB" />
                <stop offset={1} stopColor="#47EBEB" stopOpacity={0} />
            </radialGradient>
        </defs>
    </svg>
);
