// https://www.taniarascia.com/redux-react-guide/
import * as actions from 'actions/cubestokenActions';

export const initialState = {
  posts: [],
  loading: false,
  hasErrors: false,
  token: '',
};

export default function cubestokenReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CUBETOKEN:
      return { ...state, token: action.payload };
    case actions.GET_CUBETOKEN:
      return { ...state };
    default:
      return state;
  }
}
