import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { adminService } from 'dataio/HttpRequest';
import { AuthContext } from 'context/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function Groups({ user, receiveGroups }) {
  const classes = useStyles();
  const [roles, setRoles] = React.useState(user.data.roles);
  const [groups, setGroups] = React.useState([]);
  const authcontext = useContext(AuthContext);

  const handleChange = (event) => {
    let grps;
    if (event.target.checked) {
      grps = [{ id: event.target.id, name: event.target.name }, ...roles];
      setRoles(grps);
    } else {
      console.log(roles);
      grps = roles.filter((ele) => (ele.id !== parseInt(event.target.id, 10)));
      console.log(grps);
      setRoles(grps);
    }
    receiveGroups(grps);
  };

  const checkRole = (role) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    const res = roles.find((ele) => (ele.name === role.name));
    return !!res;
  };

  useEffect(() => {
    (async () => {
      let res;
      try {
        res = await adminService({
          endpoint: '/auth/groups', method: 'GET', header: authcontext.auth_token, payload: undefined,
        });
        console.log(res);
        res.error ? toast.error('Something went wrong') : setGroups(res);
      } catch (error2) {
        console.log(error2);
      }
    })();
  }, []);

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Assign role</FormLabel>
        <FormGroup row>
          {
            groups.map((ele) => (<FormControlLabel
                key={ele.id}
                control={<Checkbox checked={checkRole(ele)} onChange={handleChange} id={ele.id} name={ele.name} />}
                label={ele.name}
              />))
          }
        </FormGroup>

      </FormControl>

    </div>
  );
}
