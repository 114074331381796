import React, { useEffect, useContext } from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch, useParams,
} from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserMenu from 'components/general/UserMenu';
import { AuthContext } from 'context/UserContext';
import { MainListItems } from './ListItems';
import { TopBanner } from 'components/general/TopBanner';
import { Footer } from 'components/general/Footer';
const qs = require('query-string');


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#344572',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginTop: 10,
    marginLeft: 20,
    textAlign: 'left',
    
    fontWeight: 900
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    // overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    minHeight: '88%'
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function GenericLayout(props) {
  const classes = useStyles();
  // const { quiz, user } = useParams();
  const [open, setOpen] = React.useState(false);
  const [datecut, setDatecut] = React.useState(undefined);
  const context = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getTitle = () => {
    const titles = {
      '/labs/games': 'UEFA 2024',
    };
    return titles[window.location.pathname];
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  // apply the effect only once after the initial render; pass [] as 2nd arg
  useEffect(() => {
    const now = new Date();
    let date = qs.parse(window.location.search, { ignoreQueryPrefix: true }).d;

    // if date is not passed as url parameter then get current year
    if (!date) { date = now.getFullYear(); }
    setDatecut(`date:${date}`);
  }, []);

  // console.log(datecut);

  if (!datecut) {
    return (<div className={classes.root}></div>);
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <TopBanner />
        <Toolbar className={classes.toolbar}>

        {context.permissions.length > 1
            && <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
        }
              <img src={`${process.env.PUBLIC_URL}/img/logo8.png`} style={{ height: '30px' }} className="App-logo" alt="logo" />

          {/* <Hidden mdDown>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
            </Hidden> */}

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {getTitle()}
            </Typography>

          <UserMenu {...props} />
        </Toolbar>
      </AppBar>

        {context.permissions.length > 1
        && <Drawer
                variant="permanent"
                classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
                </div>


                <List>
                <MainListItems

                ></MainListItems>
                </List>
                <Divider />

        </Drawer>
        }

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="xl" className={classes.container}>

          <Grid item container xs={12}>
            {props.children}
          </Grid>

        </Container>
        <Footer />
      </main>
    </div>
  );
}
