// https://www.taniarascia.com/redux-react-guide/
import * as actions from 'actions/optionsActions';

export const initialState = {
  allowdateranges: false,
};

export default function cubestokenReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ALLOWDATERANGE:
      return { ...state, allowdateranges: action.payload };
    case actions.GET_ALLOWDATERANGE:
      return { ...state };
    default:
      return state;
  }
}
