import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import { AuthContext } from 'context/UserContext';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(-2),
    },
  },
  buttons: {
    color: '#ffffff',

  },

}));

const BetMenu = (props) => {
  const [anchorElProfile, setAnchorElProfile] = useState(null);

  const context = useContext(AuthContext);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };


  // const isAdmin = context.roles.filter((ele) => (ele.name === 'admin'));


  return (
    <div className={classes.account}>


      <Button
        variant="contained"
        color="primary"
        aria-controls="bet-menu"
        aria-haspopup="true"
        onClick={handleClick} >
        <Icon >menu</Icon> Menu
        </Button>

      <Menu
        id="profile-menu"
        anchorEl={anchorElProfile}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleCloseProfile}
      >
        <MenuItem disabled={true} onClick={handleCloseProfile} style={{ fontSize: 11 }}>Escolha uma opção</MenuItem>

        <MenuItem
          onClick={() => {
            props.onClickBetAdmin();
            handleCloseProfile();
          }
        }
          >
          {props.mode ? 'Resultados dos jogos' : 'Apostas'}
        </MenuItem>
        <MenuItem
            onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/classification`)}>
                Classificação equipas
        </MenuItem>
        <MenuItem
            onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/betrank/${props.type}`)}>
                Ranking participantes
        </MenuItem>

        <Divider />

        {/* <MenuItem
            onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/quiz`)}>
                Quiz
        </MenuItem> */}


      </Menu>

    </div>
  );
};

export default BetMenu;
