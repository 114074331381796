import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import { toast } from 'react-toastify';
import { Link, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import GamesTable from 'components/games/GamesTable';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Quiz from 'components/games/Quiz';
import Score from 'components/games/Score';
import Rank from 'components/games/Rank';
import DoneGames from 'components/games/DoneGames';
import Bet from 'components/games/Bet';
import BetRank from 'components/games/BetRank';
import BetClass from 'components/games/BetClass';
import QuizButtons from 'components/games/QuizButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  main: {
    // width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    width: '100%',
  },
  dashboard: {
    width: '100%',
    marginBottom: 20,
  },
}));


function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
  );
}

export default function Games(props) {
  const [listVisible, setListVisible] = useState(true);
  const [mode, setMode] = useState('table');
  const [data, setData] = useState(undefined);
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const doquiz = () => {
    // setData({ event_date: undefined, value: '', level2: { id: undefined } });
    setMode('doquiz');
    props.history.push('/games/doquiz/1');
  };

  const back = () => {
    setListVisible(!listVisible);
    setMode('');
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    /* (async () => {
          setLoadingData(true);
          const res = await fetchExpensesData('/expenses', `&token=${context.cubestoken}`);
          console.log(res);
          data.error ? toast.error('Something went wrong') : setData(res);
          setLoadingData(false);
        })(); */
  }, []);

  return (

        <React.Fragment >

        <div className={classes.root}>
            {/* <AppBar position="static">
                <Tabs
                variant="fullWidth"
                centered
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                >
                    <LinkTab label="Bet" href="/trash" {...a11yProps(0)} />
                    <LinkTab label="Quiz" href="/drafts" {...a11yProps(1)} />

                </Tabs>
            </AppBar> */}


                <Route exact path={`/${process.env.REACT_APP_BASEFOLDER}/games/quiz`}>
                    <QuizButtons {...props} />
                    <GamesTable {...props} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/doquiz/:quiz/`}>
                    <QuizButtons {...props} />
                    <Quiz {...props} mode={mode} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/score/:quiz/`}>
                    <QuizButtons {...props} />
                    <Score {...props} mode={mode} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/done`}>
                    <QuizButtons {...props} />
                    <DoneGames {...props} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/rank`}>
                    <QuizButtons {...props} />
                    <Rank {...props} mode={mode} />
                </Route>


                <Route exact path={`/${process.env.REACT_APP_BASEFOLDER}/games`}>
                    <Bet {...props} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/betrank/:type`}>
                    <BetRank {...props} />
                </Route>

                <Route path={`/${process.env.REACT_APP_BASEFOLDER}/games/classification`}>
                    <BetClass {...props} />
                </Route>


            </div>


        </React.Fragment>


  );
}
