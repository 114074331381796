import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import { toast } from 'react-toastify';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import {
  useParams,
  Link, Route,
} from 'react-router-dom';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import { AuthContext } from 'context/UserContext';
import { gamesDataService } from 'dataio/HttpRequest';

/*const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiAccordionSummary: {
      // Name of the rule
      content: {
        margin: '12px 0',
        // display: 'flex',
        flexGrow: 1,
        transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
});*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(6),
  },
  main: {
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '35%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  bet: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#3f50b5',
    contrastText: '#fff',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    flexBasis: '50%',
    flexShrink: 0,
  },
  secondaryHeading: {
    flexBasis: '25%',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  margin: {
    width: 60,
  },
  summary: {
    margin: '12px 0',
    flexGrow: 1,
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

export default function BetRank(props) {
  const { type } = useParams();
  const [scores, setScores] = useState([]);
  const [count, setCount] = useState(0);

  const authcontext = useContext(AuthContext);
  const classes = useStyles();

  const getData = async () => {
    const res = await gamesDataService(
      `/matches/betrank/${type}`,
      authcontext.auth_token,
    );
    console.log(res);
    if (res.status === 'success') {
      setScores(res.data);
      setCount(res.count);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
              <React.Fragment>

                    <div className={classes.root}>

                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            fullWidth={false}
                            style={{ marginBottom: 5, marginTop: 5 }}
                            onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games`)}
                        >Voltar
                        </Button>

                        <Typography
                                color="secondary"
                                align="center"
                                variant="body2"
                                >{`After ${count} jogos concluídos`}
                            </Typography>

                        {

                            scores
                              .sort((a, b) => ((a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0)))
                              .map((item, index) => (


                            <Typography
                                color="primary"
                                align="center"
                                variant="body1"
                                >{`${index + 1}º ${item.name} : ${item.score} pontos`}
                            </Typography>


                              ))
                        }
                    </div>

              </React.Fragment>
  );
}
