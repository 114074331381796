
import React from 'react';
import Link from '@material-ui/core/Link';
import { Redirect, withRouter } from 'react-router-dom';

//  <div> Home </div>

function HomePage(props) {
  return (
    <Redirect to={`/${process.env.REACT_APP_BASEFOLDER}/signin`} />
  );
}

export default withRouter(HomePage);
