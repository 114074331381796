import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import { AuthContext } from 'context/UserContext';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(-2),
    },
  },
  buttons: {
    color: '#ffffff',

  },
  account: {
    position: 'absolute',
    right: 0,
    bottom: 10,
  },
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UserMenu = (props) => {
  console.log(props);
  const [anchorElProfile, setAnchorElProfile] = useState(null);

  const context = useContext(AuthContext);
  const classes = useStyles();

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };


  const logout = () => {
    localStorage.removeItem("user");
    context.logout();
  };

  return (
    <div className={classes.account}>


      <Button aria-controls="profile-menu" aria-haspopup="true" onClick={handleClickProfile} >
        <Icon className={classes.buttons} >account_box</Icon>
        </Button>

      <Menu
        id="profile-menu"
        anchorEl={anchorElProfile}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleCloseProfile}
      >
        <MenuItem disabled={true} onClick={handleCloseProfile} style={{ fontSize: 11 }}>Signed in as {context.username}</MenuItem>
        {/* <MenuItem
          // onClick={handleCloseProfile}>
          onClick={() => props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/profile`)}>
          Profile
        </MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>

    </div>
  );
};

export default UserMenu;
