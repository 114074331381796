import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import {
  useParams,
  Link, Route,
} from 'react-router-dom';
import _ from 'underscore';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { gamesDataService } from 'dataio/HttpRequest';
import { AuthContext } from 'context/UserContext';
import { stratify } from 'd3';
import { htmldecode } from 'utils/Helpers';

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(400 + theme.spacing(1))]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',

    },
  },
  question: {
    flexGrow: 1,
    width: '100%',
    padding: '5px 5px 5px 5px',
    fontSize: '14px',
    textAlign: 'center',
  },
  background: {
    backgroundColor: '#c6e0b4',
  },
  button: {
    flexGrow: 1,
    width: '80%',
    padding: '5px 5px 5px 5px',
    textAlign: 'center',

    margin: 8,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  timer: {
    fontSize: '16px',
    fontWeight: 600,
  },
}));

// TODO: add landing page with instructions

export default function Quiz(props) {
  const [questions, setQuestions] = useState([]);
  const [results, setResults] = useState([]);
  const [questionid, setQuestionid] = React.useState(0);
  const [timer, setTimer] = React.useState(90);
  const classes = useStyles();
  const authcontext = useContext(AuthContext);

  const { quiz } = useParams();

  const getBackgroundColor = () => {
    if (questions[questionid]) {
      switch (questions[questionid].questions.difficulty) {
        case 'easy':
          return '#c6e0b4';
        case 'medium':
          return '#fbfba5';
        case 'hard':
          return '#f68b20';
        default:
          return '#ffffff';
      }
    }
  };

  const loadQuestions = (data, callback) => {
    const i = 0;
    data.forEach((element) => {
      element.answers = [];
      element.questions.incorrect_answers.forEach((ans) => {
        element.answers.push({ id: element.id, text: ans, correct: 'f' });
      });
      element.answers.push({ id: element.id, text: element.questions.correct_answer, correct: 't' });
      element.answers = _.shuffle(element.answers);
    });
    callback(data);
  };

  let t = 90;
  const startclock = () => setInterval(() => {
    setTimer(t--);
  }, 1000);


  const sendToServer = (endpoint, method, payload, callback) => {
    Promise.all([gamesDataService(
      endpoint,
      authcontext.auth_token,
      method,
      payload,
    )])
      .then((response) => {
        // eslint-disable-next-line no-lonely-if
        if (endpoint.indexOf('/quizinit/') !== -1) {
          loadQuestions(response[0], callback);
          startclock();
        } else if (endpoint.indexOf('/answers/') !== -1) {
          if (response[0].status === 'fail') {
            toast(response[0].message);
          } else {
            toast(response.message, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 });
            callback();
          }
        }
      });
  };

  const endQuiz = (value = undefined) => {
    const answers = value ? [...results, value] : results;
    const payload = {
      game_id: quiz, answers, responsetime: 90 - timer,
    };
    sendToServer('/games/answers/', 'POST', payload, () => { props.history.push(`/${process.env.REACT_APP_BASEFOLDER}/games/score/${quiz}`); });
  };

  const handleToggle = (value) => () => {
    if (questionid === questions.length - 1) {
      endQuiz(value);
    } else {
      setResults([...results, value]);
      setQuestionid(questionid + 1);
    }
  };


  const getOptionsAnswers = () => {
    let answers = [];
    if (questions.length && questions[questionid].answers) {
      answers = questions[questionid].answers;
      answers.map((ele) => {
        ele.text = htmldecode(String(ele.text));
      });
    }
    return answers;
  };

  const getQuestion = () => {
    let question = '';
    if (questions.length > 0 && questions[questionid].questions) {
      question = htmldecode(questions[questionid].questions.question);
      // question = htmldecode(question);
    }

    return question;
  };

  if (timer === 0) {
    endQuiz();
  }


  useEffect(() => {
    const payload = {
      game_id: quiz,
    };
    sendToServer('/games/quizinit/', 'POST', payload, setQuestions);
  }, [quiz]);

  return (
          <React.Fragment >

          <Grid container spacing={0}>
          <Grid style={{ paddingRight: 5 }} item xs={12} md={12} >
            <main className={classes.main}>
            <Box component="span" m={1} className={classes.timer}>
              {timer} seg.
            </Box>

            <div className={classes.progress}>
              <LinearProgress color="secondary" variant="determinate" value={100 - ((90 - timer) * 1.11)} />
            </div>

            <Paper style={{ backgroundColor: getBackgroundColor() }}>
            <List >

                <ListItem key={questions.game_id} role={undefined} dense button >
                  <ListItemText className={classes.question}


                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="h6"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {getQuestion()}
                      </Typography>
                    </React.Fragment>
                  }


                  />
                </ListItem>

                {getOptionsAnswers().map((option, index) => (
                    <Button key={option.id + index}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleToggle(option)}>
                      {option.text}
                    </Button>

                ))}
        </List>
        </Paper>
        </main>
        </Grid>
        </Grid>

        </React.Fragment>
  );
}
