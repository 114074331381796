
import { DateFormatter } from 'utils/DateFormatter';
import {
  nestData, unPivotDims, sumNumericValues,
  sortByTot, replaceKeyNames, unPivotAggs, nestData1Lvl,
  getCubesDataByKey, objToArray, filterArrayByKey, renameNameKey,
  renameKeys, topRows, flattenArray, roundNumber, dateToUnixTime,
} from './DataOps';


const executeFnct = (fnstring, data, args) => {
  let result;
  switch (fnstring) {
    case 'nestData': result = nestData(data, ...args); break;
    case 'nestData1Lvl': result = nestData1Lvl(data, ...args); break;
    case 'unPivotDims': result = unPivotDims(data, ...args); break;
    case 'unPivotAggs': result = unPivotAggs(data, ...args); break;
    case 'sumNumericValues': result = sumNumericValues(data, ...args); break;
    case 'sortByTot': result = sortByTot(data, ...args); break;
    case 'replaceKeyNames': result = replaceKeyNames(data, ...args); break;
    case 'getCubesDataByKey': result = getCubesDataByKey(data, ...args); break;
    case 'roundNumber': result = roundNumber(data, ...args); break;
    case 'objToArray': result = objToArray(data, ...args); break;
    case 'filterArrayByKey': result = filterArrayByKey(data, ...args); break;
    case 'renameNameKey': result = renameNameKey(data, ...args); break;
    case 'renameKeys': result = renameKeys(data, ...args); break;
    case 'topRows': result = topRows(data, ...args); break;
    case 'flattenArray': result = flattenArray(data, ...args); break;
    case 'dateToUnixTime': result = dateToUnixTime(data, ...args); break;

    default:
      console.log(`Sorry, no function named ${fnstring}.`);
  }
  return result;
};

const formatNumber = (_number, _decimals, _sign = undefined) => {
  const res = _number * 100;
  const exp = Math.pow(10, _decimals);
  let result = Math.round(res * exp) / exp;
  if (!isNaN(result)) {
    if (_sign) {
      result = `${result} ${_sign}`;
    }
    return result;
  }
  return '---';
};

const compactObject = (obj) => {
  const res = {};
  res[obj.key] = obj.value;
  return res;
};

const arrayToSingleObj = (obj, item) => Object.assign(obj, { [item.key]: item.value });

const getColumnsFromObj = (obj) => {
  const keys = Object.keys(obj);
  const fields = keys.map((ele) => ({ title: ele, field: ele }));
  return fields;
};

const getHierarchicalLevelsDate = (obj, val = null) => {
  let value;
  let datepoints = [];
  let result = '';
  const { levels, cells } = obj;
  const key = Object.keys(levels)[0];

  if (val === null) {
    const len = cells.length;
    // value = cells[len - 1];
    datepoints.push(len - 1);
  } else {
    if (Array.isArray(val)) {
      datepoints = val;
    } else {
      datepoints.push(val);
    }
    // value = cells[val];
  }

  datepoints.forEach((element) => {
    value = cells[element];

    levels[key].forEach((element) => {
      const datekey = `date.${element}`;
      result += `,${String(value[datekey])}`;
    });
    // result = result.slice(1);
    result += '-';
  });

  // remove 1st char from result
  result = result.slice(1).slice(0, -1).replace('-,', '-');
  // remove last char from result
  // result = result.slice(0, -1);
  // result = result.replace('-,', '-');
  return result;
};

const processDateCuts = (datecut) => {
  let result;
  datecut ? result = `${datecut}|` : result = '';
  return result;
};

const processAditionalCuts = (dimcuts) => {
  let othercuts = '';
  let i = 0;
  let separator;

  if (dimcuts) {
    const keys = Object.keys(dimcuts);
    keys.forEach((element) => {
      const mainKey = element.split('-')[0];
      const obj = dimcuts[element];
      if (obj.values.length > 0) {
        if (i === 0) {
          separator = '';
        } else {
          separator = '|';
        }
        // othercuts += separator+mainKey+'@'+obj['attr']+'.'+obj['attr']+':'+obj['values'].join(';');
        othercuts += `${separator + mainKey}@${obj.attr}:${obj.values.join(';')}`;
        i += 1;
      }
    });
  }

  // console.log(othercuts);
  return othercuts;
};

const removeFisrtLastPipe = (string) => {
  string = string.replace(/\|\s*$/, '');
  string = string.replace(/^\|\s*/, '');
  return string;
};

const removeDuplicatePipes = (string) => string.replace('||', '|');

const processAllCuts = (string) => removeFisrtLastPipe(removeDuplicatePipes(string));

const generateUUID = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

const shouldGetCubesData = (agg, drill = true) => {
  let res = false;
  if (drill) {
    if (agg && agg.cube && agg.aggregates && agg.drilldown) {
      res = true;
    }
  } else if (agg && agg.cube && agg.aggregates) {
    res = true;
  }

  return res;
};

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const dimensionIsTime = (dimension) => {
  let res = false;
  if (dimension && dimension.indexOf('date') >= 0) {
    res = true;
  }
  return res;
};

const getTimeOrder = (dimension) => {
  let res;
  const timeOrders = {
    date: 'date.year',
    'date:year': 'date.year',
    'date:semester': 'date.year,date.semester',
    'date:quarter': 'date.year,date.quarter',
    'date:month': 'date.year,date.quarter,date.month',
    'date:week': 'date.year,date.quarter,date.month,date.week',
    'date:day': 'date.year,date.quarter,date.month,date.day',
  };

  if (dimension in timeOrders) {
    res = timeOrders[dimension];
  }

  return res;
};

const getDefaultRoute = (permissions, observations) => {
  let page;
  if (observations && observations.defaultpage) {
    page = permissions.filter((perm) => perm.name === observations.defaultpage);
  } else {
    page = permissions;
  }
  return page[0].name;
};

const getNestData1LvlArgs = (dateformat) => {
  let res;
  switch (dateformat) {
    case 'MM-YYYY':
      res = ['date.month', 'date.year'];
      break;
    case 'DD-MM-YYYY':
      res = ['date.day', 'date.month', 'date.year'];
      break;
    default:
      break;
  }
  return res;
};

const getDrillOrder = (datecut) => {
  let drill;
  let order;
  let dateformat;
  const dateObj = new DateFormatter(datecut);
  if (dateObj.identifyDateGranularity()) {
    // console.log(dateObj.naturalformat(), dateObj.identifyDateGranularity());
    drill = dateObj.getDrillDown();
    order = dateObj.getOrder();
    dateformat = dateObj.getDateFormat();
  } else {
    drill = 'date:month';
    order = 'date.year,date.month';
    dateformat = 'MM-YYYY';
  }

  return { drill, order, dateformat };
};

const htmldecode = (str) => str
  .replace(/&#039;/g, "'")
  .replace(/&quot;/g, '"')
  .replace(/&amp;/g, '&')
  .replace(/&AMP;/g, '&')
  .replace(/&ldquo;/g, '"')
  .replace(/&rdquo;/g, '"')
  .replace(/&ndash;/g, '-')
  .replace(/&eacute;/g, 'é')
  .replace(/&aacute;/g, 'á')
  .replace(/&ntilde;/g, 'ñ')
  .replace(/&atilde;/g, 'ã');


export {
  executeFnct, formatNumber, compactObject,
  arrayToSingleObj, getColumnsFromObj, getHierarchicalLevelsDate,
  processAditionalCuts, generateUUID, shouldGetCubesData, capitalizeFirstLetter,
  processDateCuts, processAllCuts, dimensionIsTime, getTimeOrder, getDefaultRoute,
  getNestData1LvlArgs, getDrillOrder, htmldecode,
};
