
// Create Redux action types
export const SET_CUBESMODEL = 'SET CUBESMODEL';
export const GET_CUBESMODEL = 'GET CUBESMODEL';


export const setCubesModel = (option) => ({
  type: SET_CUBESMODEL,
  payload: option,
});


// Combine them all in an asynchronous thunk
export function cubesModel(option) {
  return async (dispatch) => {
    dispatch(setCubesModel(option));
  };
}
