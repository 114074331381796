import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authService } from 'dataio/HttpRequest';
import { validateLength, validateEmail } from 'utils/validators';
import Copyright from './Copyright';
const logo = `${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGO}`

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  message: {
    color: 'red',
    fontSize: 12,
  },
}));

function Recovery(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const recover = async () => {
    const res = await authService({
      endpoint: '/auth/recover/', method: 'POST', credentials: { email },
    });

    setMessage(res.message);
  };

  const checkDisabled = (event, val) => {
    const lenerr = validateLength({ email });
    const emailerr = validateEmail({ email });
    const allerr = lenerr.concat(emailerr);
    const enabled = allerr.every((x) => x);
    return !enabled;
  };


  const onChangeEmail = (event, val) => {
    setEmail(event.target.value);
  };


  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ height: 40 }} className="App-logo" alt="logo" />

          <TextField
            value={email}
            onChange={onChangeEmail}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Button
            onClick={recover}
            disabled={checkDisabled()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Recover Password
          </Button>

          <span className={classes.message}>{message}</span>


      </div>


      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>

  );
}

export default Recovery;
