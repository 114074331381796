import React, {
  useEffect, useState, useContext, Component,
} from 'react';
import { toast } from 'react-toastify';
import Flag from 'react-world-flags';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import {
  useParams,
  Link, Route,
} from 'react-router-dom';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { AuthContext } from 'context/UserContext';
import { gamesDataService } from 'dataio/HttpRequest';
import BetMenu from 'components/games/BetMenu';

/*const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiAccordionSummary: {
      // Name of the rule
      content: {
        margin: '12px 0',
        // display: 'flex',
        flexGrow: 1,
        transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
});*/


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(6),
  },
  main: {
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '35%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  bet: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#3f50b5',
    contrastText: '#fff',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    flexBasis: '50%',
    flexShrink: 0,
  },
  secondaryHeading: {
    flexBasis: '25%',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  margin: {
    width: 60,
  },
  summary: {
    margin: '12px 0',
    // display: 'flex',
    flexGrow: 1,
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Bet(props) {
  const [matches, setMatches] = useState([]);
  const [bets, setBets] = useState({});
  const [team1, setTeam1] = useState({});
  const [team2, setTeam2] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [competition, setCompetition] = useState();
  const [betmode, setBetmode] = useState(true);
  const [filtermatches, setFiltermatches] = useState({ unplayed: false, today: false });
  const [showrules, setShowrules] = useState(false);


  const context = useContext(AuthContext);
  const isAdmin = context.roles.filter((ele) => (ele.name === 'admin'));
  const classes = useStyles();


  const getBetInfo = async (_id) => {
    const res = await gamesDataService(
      `/matches/${_id}/${competition.type}`,
      context.auth_token,
    );
    setBets(res.data);
  };

  const getData = async () => {
    const res = await gamesDataService(
      '/matches/',
      context.auth_token,
    );
    // console.log(res);
    if (res.status === 'success') {
      setMatches(res.data);
      setCompetition(res.competition);
    }
  };

  const save = async (item) => {
    let method;
    let endpoint;
    if (!betmode) {
      method = 'PUT';
      endpoint = '/matches/setmatch/';
    } else {
      method = 'POST';
      endpoint = '/matches/';
    }

    if (team1[item.id] === undefined || team2[item.id] === undefined) {
      toast.error(
        'please set the 2 team scores',
        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 },
      );
      return;
    }

    const payload = {
      match_id: item.id,
      result: { final: { team1: team1[item.id], team2: team2[item.id] } },
    };

    const res = await gamesDataService(
      endpoint,
      context.auth_token,
      method,
      payload,
    );


    toast.success(
      res.message,
      { position: toast.POSITION.TOP_CENTER, autoClose: 2000 },
    );
    if (res.status === 'success') {
      getData();
    }
  };


  const handleChange = (item) => (event, isExpanded) => {
    if (!betmode) {
      return;
    }
    if (item.mybet.length === 1 || item.result) {
      setExpanded(isExpanded ? `panel${item.id}` : false);
      if (isExpanded) {
        getBetInfo(item.id);
      }
    }
  };


  const onChangeTeam1 = (item) => (event, val) => {
    const obj = {};
    obj[item.id] = parseInt(event.target.value, 10);
    const newvalue = { ...team1, ...obj };
    setTeam1(newvalue);
  };

  const onChangeTeam2 = (item) => (event, val) => {
    const obj = {};
    obj[item.id] = parseInt(event.target.value, 10);
    const newvalue = { ...team2, ...obj };
    setTeam2(newvalue);
  };

  const checkBetDisabled = (item) => {
    if (!betmode) {
      return false;
    }
    if (team1[item.id] !== undefined && team2[item.id] !== undefined) {
      return false;
    }
    //! (team1[item.id] && team2[item.id])
    return true;
  };

  const getResult = () => (bets.result ? `${bets.result.final.team1} - ${bets.result.final.team2}` : '___');

  const onClickBetAdmin = () => {
    setBetmode(!betmode);
    setTeam1({});
    setTeam2({});
  };

  const checkViewBtn = (item) => {
    if (!betmode) {
      return isAdmin.length === 1;
    }

    if (item.mybet.length === 0) {
      return true;
    }
    return false;
  };

  const checkTextDisabled = (item) => {
    if (!betmode) {
      return !isAdmin.length === 1;
    }
    if (item.mybet.length > 0) {
      return true;
    }
    return false;
  };

  const showValue = (item, team) => {
    let result;

    if (team === 'team1') {
      if (!betmode) {
        result = item.result ? item.result.final.team1 : '';
      } else {
        result = item.mybet.length > 0 ? item.mybet[0].result.final.team1 : '';
      }


      if (team1[item.id] === undefined) {
        return result;
      }
      return team1[item.id];
    } if (team === 'team2') {
      if (!betmode) {
        result = item.result ? item.result.final.team2 : '';
      } else {
        result = item.mybet.length > 0 ? item.mybet[0].result.final.team2 : '';
      }
      if (team2[item.id] === undefined) {
        return result;
      }
      return team2[item.id];
    }
  };

  const getAccordion = (item) => (

        <Accordion
            expanded={expanded === `panel${item.id}`}
            style={{ backgroundColor: item.result ? '#f5ded9' : '#f0f7f8' }}
            onChange={handleChange(item)}
            >

            <AccordionSummary
            style={{ backgroundColor: item.result ? '#f5ded9' : '#f0f7f8' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            // className={classes.summary}
            >
                <div className={classes.summary}>

            <Typography className={classes.heading}>
                <Flag code={item.short_team1} height="35" width="35" style={{ borderRadius: '20%' }} />
                &nbsp;&nbsp;
                <Flag code={item.short_team2} height="35" width="35" style={{ borderRadius: '20%' }} />

                <Box>
                <TextField
                    id="team1"
                    // className={classes.margin}
                    label={item.team1}
                    type="number"
                    inputProps={{
                      max: 99, min: 0,
                    }}
                    value={showValue(item, 'team1')}
                    variant="outlined"
                    disabled={checkTextDisabled(item)}
                    onChange={onChangeTeam1(item)}
                    required={false}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />

                    &nbsp;
                 <TextField
                    id="team2"
                    // className={classes.margin}
                    required={false}
                    type="number"
                    label={item.team2}
                    InputProps={{
                      inputProps: {
                        max: 99, min: 0,
                      },
                    }}
                    value={showValue(item, 'team2')}
                    variant="outlined"
                    disabled={checkTextDisabled(item)}
                    onChange={onChangeTeam2(item)}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                </Box>


            { checkViewBtn(item)
                && <Button
                size="small"
                // className={classes.margin}
                variant="contained"
                onClick={() => { save(item); }}
                disabled={checkBetDisabled(item)}
                color={betmode ? 'primary' : 'secondary'}>
                { betmode ? 'BET' : 'SET'}
            </Button>

            }
            </Typography>


            <Typography
                className={classes.secondaryHeading}>
                    {item.date ? moment(item.date).format('ll HH:mm') : '---' }
            </Typography>


            <Typography className={classes.secondaryHeading}>{item.countbets} bets</Typography>
            </div>
            </AccordionSummary>

            <AccordionDetails
                className={classes.main}
                style={{ backgroundColor: item.result ? '#f5ded9' : '#f0f7f8' }}
                >


                <List >

                    { bets.team1 && bets.team2
                    && <Typography
                        color="secondary"
                        style={{ fontSize: 12, fontWeight: 600 }}
                        align="center"
                        gutterBottom
                        >{`${bets.team1.toUpperCase()} - ${bets.team2.toUpperCase()}: ${getResult()} FT`}
                    </Typography>
                    }

                    { bets.bets

                    && bets.bets.map((bet, index) => {
                      const labelId = `checkbox-list-label-${bet.id}`;

                      return (
                        <div>

                        <div className={classes.bet}>
                            <span>{`${bet.name} : ${bet.result.final.team1} - ${bet.result.final.team2}`}</span>
                            &nbsp;&nbsp;
                            { bet.score
                                && <span style={{ color: 'red' }}>{`(${bet.score || 0})`}</span>
                            }

                        </div>

                        </div>
                      );
                    })}
                </List>

            </AccordionDetails>
        </Accordion>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getData();
  }, []);

  function createMarkup() {
    return { __html: competition && competition.rules };
  }

  return (
              <React.Fragment>

                    <div className={classes.root}>
                    {/* <Typography
                        variant="body"
                        color="primary"
                        gutterBottom
                    >
                        {competition && competition.name}
                    </Typography> */}


                    <ButtonGroup color="primary" aria-label="outlined primary button group"
                        style={{ marginBottom: 10 }}
                    >
                        <Button
                            // onClick={() => { setShowrules(!showrules); }}
                            onClick={handleClick}
                        >
                                Regras
                            </Button>
                        <BetMenu mode={betmode} type={competition && competition.type} onClickBetAdmin={onClickBetAdmin} {...props} />

                    </ButtonGroup>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                    >
                        <Typography className={classes.typography}>
                            <span dangerouslySetInnerHTML={createMarkup()} />
                        </Typography>
                    </Popover>


                    <Box>
                        <Typography
                            variant="body2"
                            color="primary"
                            gutterBottom
                            style={{ display: showrules ? 'inline' : 'none' }}
                        >
                            {competition && competition.rules}
                        </Typography>
                    </Box>

                    <Box>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={filtermatches.unplayed}
                            onChange={ () => setFiltermatches({ unplayed: !filtermatches.unplayed, today: false })}
                            name="checkedA"
                            color="primary"
                        />
                        }
                        label="Jogos por realizar"
                    />
                    <FormControlLabel
                        control={
                        <Switch
                            checked={filtermatches.today}
                            onChange={ () => setFiltermatches({ unplayed: false, today: !filtermatches.today })}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Jogos a relizar hoje"
                    />
                    </Box>


                    { /* filtermatches.unplayed
                      ? matches.filter((ele) => !ele.result).map((item) => getAccordion(item))
                      : matches.map((item) => getAccordion(item)) */
                    }

                    { filtermatches.unplayed
                       && matches.filter((ele) => !ele.result).map((item) => getAccordion(item))
                    }

                    { filtermatches.today
                       && matches.filter((ele) => moment(ele.date, 'MM/DD/YYYY, hh:mm:ss').format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')).map((item) => getAccordion(item))

                    }

                    { !filtermatches.unplayed && !filtermatches.today
                       && matches.map((item) => getAccordion(item))
                    }


                    </div>


              </React.Fragment>
  );
}
