import React, {
  useContext, useEffect, useState,
} from 'react';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router, Redirect, Route,
  Switch,
} from 'react-router-dom';
//import Expenses from 'components/expenses/Expenses';
import Games from 'components/games/Games';
//import MainGym from 'components/gym/MainGym';
//import TodoList from 'components/todo/TodoList';
import Administration from 'components/admin/Administration';
//import { userContext } from 'actions/usercontextActions';
import Profile from './components/general/Profile';
import GenericLayout from './components/dashboard/GenericLayout';
import { AuthContext, AuthProvider } from './context/UserContext';
import './App.css';
import { ROUTE, ROUTES, NoMatch } from './routes';



// const qs = require('query-string');

const AppRoute = ({
  component: Component,
  layout: Layout,
  private: isPrivate,
  isauth: isAuth,
  ...rest
}) =>

/* if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    userSession.isAuth = true;
  } */

  (
    <Route {...rest}
      render={
      // eslint-disable-next-line no-nested-ternary
      (props) => (isPrivate && !isAuth ? (
          <Redirect to={ROUTE.signin} />
      ) : (Layout
        ? (<Layout {...props}>
            <Component {...props} dashlayout={rest.name} />
          </Layout>)
        : (<Component {...props} dashlayout={rest.name} />)
      ))
    }
    />
  );


// eslint-disable-next-line no-unused-vars
function RoutesFromPermissions() {

  const context = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem('user'));
  
  let routes = [];
  let isAuth = false;
  if (user){
    routes = user.permissions.filter((perm) => perm.type === 'routes');
    isAuth  = true;
  } else {
    
    routes = context.permissions
      .filter((perm) => perm.type === 'routes');
    isAuth = context.isAuth;
  }

  if (!context.userId && user){
    context.login(user);
  } 
  /*const userSession = useContext(AuthContext);
  const routes = userSession.permissions
    .filter((perm) => perm.type === 'routes');
  const { isAuth } = userSession;*/

  const ComponentsLib = {
    //expenses: Expenses,
    //todo: TodoList,
    games: Games,
    //gym: MainGym,
    admin: Administration,
    profile: Profile,
    nomatch: NoMatch,
  };

  const LayoutLib = {
    expenses: GenericLayout,
    todo: GenericLayout,
    games: GenericLayout,
    gym: GenericLayout,
    admin: GenericLayout,
    profile: GenericLayout,
  };

  const LayoutPrivate = {
    expenses: true,
    todo: true,
    games: true,
    gym: true,
    admin: true,
    profile: true,
    nomatch: false,

  };

  const getRoleComponent = (name) => ComponentsLib[name];
  const getLayoutComponent = (name) => LayoutLib[name];
  const getLayoutPrivate = (name) => LayoutPrivate[name];
  
  if (routes && routes.length === 0) {
    return (
      <Redirect to={`/${process.env.REACT_APP_BASEFOLDER}/signin`} />
    );
  }
  return (
    routes.map((route) => (
        <AppRoute
          key={route.name}
          name={`/${process.env.REACT_APP_BASEFOLDER}/${route.name}`}
          path={`/${process.env.REACT_APP_BASEFOLDER}/${route.name}`}
          component={getRoleComponent(route.name)}
          layout={getLayoutComponent(route.name)}
          private={getLayoutPrivate(route.name)}
          // private={route.name !== '*'}
          exact={false}
          isauth={isAuth}
        />
    ))
  );
}

function App({ dispatch, cubestoken, ...props }) {
  
  // const urlparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  /*useEffect(() => {
    const i = 1;
  }, [dispatch]);*/

  return (
    <div className="App">

    <AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover={false}
      />

      <Router>
        <Switch>

            {ROUTES.map((r) => (
                  <AppRoute
                    key={r.name}
                    path={r.path}
                    component={r.component}
                    private={r.private}
                    exact={r.exact}
                  />
            ))}

            <RoutesFromPermissions
              //routes={routes}
            />

              <Redirect to='*' />
        </Switch>
      </Router>
      </AuthProvider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // token: state.session.token,
  usercontext: state.user.usercontext,
});

export default connect(mapStateToProps)(App);
