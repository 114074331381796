const validateLength = (obj) => {
  const validations = [];
  Object.keys(obj).forEach((element) => {
    validations.push(obj[element].length !== 0);
  });
  return validations;
};

const validateEmail = (obj) => {
  const validations = [];
  console.log(obj);
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  Object.keys(obj).forEach((element) => {
    validations.push(re.test(String(obj[element]).toLowerCase()));
  });
  return validations;
};

export { validateLength, validateEmail };
