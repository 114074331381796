import { combineReducers } from 'redux';

import cubestokenReducer from './cubestokenReducer';
import optionsReducer from './optionsReducer';
import cubesmodelReducer from './cubesmodelReducer';
import usercontextReducer from './usercontextReducer';


const rootReducer = combineReducers({
  session: cubestokenReducer,
  options: optionsReducer,
  cubesmodel: cubesmodelReducer,
  user: usercontextReducer,
});

export default rootReducer;
